@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_License {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/license-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  color: white;

  &Wrapper {
    max-width: 1382px;
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: $tablet-width) {
      padding: 70px 16px;
    }
  }

  &Title {
    @include landingTitle;
    text-align: center;
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include blurBGArea;
    border-radius: 40px;
    padding: 16px 8px;
    margin: 20px -16px auto; 

    @media only screen and (min-width: $tablet-width) {
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 32px 0;
      margin: 35px auto auto;
    }
    @media only screen and (min-width: $wide-width) {
      padding: 49px 31px 56px 88px;
    }
  }
}

.Landing_LicenseListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 16px 8px;
  flex-shrink: 0;
  max-width: none;
  min-width: 0;
  flex-basis: 50%;

  @media only screen and (min-width: $tablet-width) {
    flex-basis: 33.33%;
    padding: 0 8px;
  }

  @media only screen and (min-width: $wide-width) {
    padding: 0 16px;
    max-width: 320px;
    min-width: 280px;
    flex-basis: auto;
  }

  &Image {
    margin-bottom: 5px;

    img {
      height: 70px;
      width: auto;
      object-fit: scale-down;
      object-position: 50% 80%;

      @media only screen and (min-width: $tablet-width) {
        height: 100px;
      }
    }
  }

  &Description {
    margin-top: 17px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 113.5%;
    @media only screen and (min-width: $tablet-width) {      
      font-size: 17px;
    }

    strong {
      font-weight: 700;
    }
  }

  &Title {
//     margin-top: 17px;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    @media only screen and (min-width: $tablet-width) {      
      font-size: 28px;
//       margin-top: 0;
    }
  }
}