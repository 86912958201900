@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_Verification {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/verification-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  color: white;

  &Wrapper {
    max-width: 1789px;
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: $tablet-width) {
      padding: 70px 16px;
    }
    @media only screen and (min-width: $wide-width) {
      padding: 140px 16px 70px 65px;
    }
  }

  &Body {
    @include blurBGArea;
    border-radius: 32px;
    padding: 32px 16px;
    margin: auto -16px;
    color: white;
    font-style: normal;
    text-align: center;

    @media only screen and (min-width: $tablet-width) {
      padding: 50 60px;
      margin: 0;
      border-radius: 100px;
    }

    @media only screen and (min-width: $wide-width) {
      padding: 68px 139px 66px 78px;
      border-radius: 161px;
    }
  }

  &Title {
    @include landingTitle;    
    color: #00ffff;
  }

  &Description {
    margin: 16px auto 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    max-width: 665px;

    @media only screen and (min-width: $desktop-width) {
      margin-top: 39px;
      font-size: 20px;
    }
  }

  &Subtitle {
    margin-top: 36px;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.05em;

    @media only screen and (min-width: $desktop-width) {
      font-size: 28px;
    }
  }

  &List {
    margin: 30px -8px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (min-width: $tablet-width) {
      margin: 64px 0 0;
    }
    @media only screen and (min-width: $desktop-width) {
      margin: 148px 0 0;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
  }
}

.Landing_VerificationListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  width: 33.33%;
  @media only screen and (min-width: $desktop-width) {
    padding: 0;
    width: auto;
  }

  &Icon {
    img {
      max-height: 40px;
      width: auto;
      @media only screen and (min-width: $tablet-width) {
        max-height: none;
      }
    }
  }

  &Title {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;

    @media only screen and (min-width: $desktop-width) {
      margin-top: 52px;
      font-size: 18px;
    }
  }
}