@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_hero {
  display: flex;
  align-items: center; 
  text-align: left;
  width: 100%;
  position: relative;
  @include screenHeight;

  video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    border-bottom: 8px solid #041e41;
  }

  .Landing_heroWrapper {
    max-width: 1643px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 16px 40px;

    @media only screen and (min-width: $tablet-width) {
      align-items: flex-start;
      padding: 120px 16px 70px;
    }

    @media only screen and (min-width: $wide-width) {
      padding: 220px 16px 70px;
    }
  }

  .Landing_heroDesc {
    position: relative;
    z-index: 1;
    max-width: 620px;
    width: 100%;
    text-align: left;

    .Landing_heroTitle {
      margin: 0 0 15px 0;
      color: $primaryText;
      font-weight: 400;
      font-size: 28px;
      line-height: 120%;
      text-align: center;

      @media only screen and (min-width: $tablet-width) {
        margin: 0 0 26px 0;
        font-size: 50px;
        text-align: left;

        b {
          font-size: 56px;
        }
      }
    }

    .Landing_heroText {
      font-size: 14px;
      line-height: 160%;
      padding: 0;
      margin: 0 0 30px 0;
      color: $primaryText;
      text-align: center;
      text-shadow: 1px 1px #000;

      @media only screen and (min-width: $tablet-width) {
        font-size: 18px;
        margin: 0 0 48px 0;
        text-align: left;
      }
    }

    .Landing_heroBtns {
      display: flex;
      justify-content: center;
      margin: -8px;

      @media only screen and (min-width: 560px) {
        flex-direction: row;
      }

      @media only screen and (min-width: $tablet-width) {
        justify-content: flex-start;
        margin: -8px -16px;
      }

      .button {
        margin: 8px;

        @media only screen and (min-width: $tablet-width) {
          justify-content: flex-start;
          margin: 8px 16px;
        }
      }
    }
  }

  .Landing_heroScroll {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(50%, 50%);
    transform: translateX(-50%);
    cursor: pointer;
    width: 12px;
    height: 100px;
    background-size: contain;
    background-image: url('~@/assets/img/landing/scroll-icon.svg');

    @media only screen and (min-width: $tablet-width) {
      width: 15px;
      height: 128px;
    }
  }
}
