@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































// @import '@/assets/scss/_variables.scss';
// @import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

.Landing_wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  @include landingMontserratFont;
}

.SectionContent > *:last-child {
  margin-bottom: 0;
}
