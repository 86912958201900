@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_FeesAndLimits {
  display: flex;
  align-items: center;
  background-image: url('~@/assets/img/landing/fees-and-limits-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
}
.Landing_FeesAndLimitsWrapper {
  // max-width: 1362px;
  max-width: 1660px;
  width: 100%;
  padding: 40px 16px;
  margin: 0 auto;
  @media only screen and (min-width: $tablet-width) {
    padding: 105px 16px 70px;
  }
  @media only screen and (min-width: $wide-width) {
    padding: 140px 16px 70px 64px;
  }
}
.Landing_FeesAndLimitsBody {
  @media only screen and (min-width: $wide-width) {
    padding: 83px 139px 106px 96px;
    @include blurBGArea;
    border-radius: 161px;
  }
}
.Landing_FeesAndLimitsTitle {
  @include landingTitle;
  margin-bottom: 30px;
  text-align: center;
  @media only screen and (min-width: $tablet-width) {
    margin-bottom: 67px;
  }
}