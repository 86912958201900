@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

.Landing_CauriPay {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/bank-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  color: white;

  &Wrapper {
    max-width: 1613px;
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: $tablet-width) {
      padding: 70px 16px;
    }
    @media only screen and (min-width: $wide-width) {
      padding: 70px 44px 140px 16px;
    }
  }

  &Logo {
    text-align: center;    
    height: 1.5em;
    margin-bottom: 14px;

    @media only screen and (min-width: $tablet-width) {
      height: 2.5em;
    }

    img {
      max-height: 100%;
      width: auto;
      max-width: 100%;
      margin: auto;
    }
  }

  &Subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: .5em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;

    @media only screen and (min-width: $tablet-width) {
      margin-bottom: 64px;
      letter-spacing: 1.09em;
    }

    @media only screen and (min-width: $desktop-width) {
      margin-bottom: 171px;
      font-size: 20px;
    }

  }

  &List {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -16px -8px;
    @media only screen and (min-width: $tablet-width) {
      flex-wrap: nowrap;
      margin: -16px -16px;
    }
  }
}

.Landing_CauriPayListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-basis: 50%;
  padding: 16px 8px;
  @media only screen and (min-width: $tablet-width) {
      flex-basis: 33.33%;
      padding: 16px 16px;
  }

  &Icon {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @media only screen and (min-width: $tablet-width) {
      width: 120px;
      height: 120px;
      border: 2px solid #ffffff;
    }

    @media only screen and (min-width: $desktop-width) {
      width: 142px;
      height: 142px;
      border-width: 8px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: scale-down;
      object-position: 50% 50%;
    }
  }

  &Text {
    margin-top: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    max-width: 420px;

    @media only screen and (min-width: $tablet-width) {
      margin-top: 30px;
      font-size: 24px;
      line-height: 160%;
    }

    @media only screen and (min-width: $wide-width) {
      margin-top: 43px;
//       font-size: 38px;
      font-size: 32px;
      line-height: 180%;
    }
  }
}