@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_Team {
  background-image: url('~@/assets/img/landing/team-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  color: white;
  
  &Wrapper {
    max-width: 1632px;
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: $tablet-width) {
      padding: 70px 16px;
    }
    @media only screen and (min-width: $wide-width) {
      padding: 140px 16px;
    }
  }

  &Title {
    @include landingTitle;
    text-align: center;
    margin-bottom: 30px;    
    @media only screen and (min-width: $tablet-width) {
      font-size: 70px;
    }    
    @media only screen and (min-width: $wide-width) {
      margin-bottom: 109px;    
    }

    &.second {
      margin-top: 64px;
      margin-bottom: 30px; 

      @media only screen and (min-width: $tablet-width) {
        margin-top: 150px;
        margin-bottom: 80px; 
      }

      @media only screen and (min-width: $wide-width) {
        margin-top: 225px;
        margin-bottom: 131px; 
      }
    }
  }

  &ClientList {
    margin: -10px 0;
    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 25%));
      grid-gap: 20px;
      justify-content: center;
    }
    @media only screen and (min-width: $desktop-width) {
      margin: 0;
    }
  }

  &List {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -10px 0;
    @media only screen and (min-width: $desktop-width) {
      margin: 0;
    }
  }

  &Client {
    margin: 10px 0;
    height: 120px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: $tablet-width) {
      height: 190px;
    }

    @media only screen and (min-width: $desktop-width) {
      margin: 0;
    }

    img {
      max-width: 70%;
    }
  }
}

.Landing_TeamMember {
  width: 100%;
  background: rgba(20, 20, 20, 0.5);
  border-radius: 24px;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0;

  @media only screen and (min-width: $tablet-width) {
    width: calc(33.33% - 10px);
  }
  @media only screen and (min-width: $desktop-width) {
    margin: 0;
    padding: 63px 25px 35px;
  }

  &Photo {
    --diametr: 160px;
    width: var(--diametr);
    height: var(--diametr);
    border-radius: 50%;
    overflow: hidden;

    @media only screen and (min-width: $desktop-width) {
      --diametr: 200px;
    }
    @media only screen and (min-width: $wide-width) {
      --diametr: 253px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &Name {
    margin-top: 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    @media only screen and (min-width: $tablet-width) {
      margin-top: 55px;
      font-size: 24px;
    }
  }

  &Position {
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    color: #00FFFF;
//     margin-top: 3px;
    @media only screen and (min-width: $tablet-width) {
      font-size: 20px;
    }
  }

  &Description {
    margin-top: 10px;
    position: relative;
    font-size: 14px;
    padding-bottom: 17px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 136px;
      height: 1px;
      background: #00FFFF;
    }
    @media only screen and (min-width: $tablet-width) {
      font-size: 16px;
      margin-top: 25px;
    }

    /*&--big {
      &:after {
        @media only screen and (min-width: $desktop-width) {
          top: calc(100% + 16px);
        }
      }
    }*/
  }
}