@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_NFTBroker {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/nft-broker-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
}
.Landing_NFTBrokerWrapper {
  max-width: 1100px;
  width: 100%;
  padding: 40px 16px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  @media only screen and (min-width: $tablet-width) {
    padding: 70px 16px;
  }
  @media only screen and (min-width: $tablet-width) {
    padding: 140px 16px;
  }
}
.Landing_NFTBrokerBody {
  max-width: 536px;
}
.Landing_NFTBrokerTitle {
  @include landingTitle;
  margin-bottom: 27px;
  color: #00ffff;
}
.Landing_NFTBrokerText {
  font-size: 16px;
  line-height: 180%;
  color: $primaryText;
  margin-bottom: 20px;
  @media only screen and (min-width: $tablet-width) {
    margin-bottom: 89px;
    font-size: 20px;
  }
}
.Landing_NFTBrokerList {
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    // --diametr: 46px;
    // --diametr-sm: 18px;
    display: flex;
    align-items: center;
    min-height: 46px;
    margin-bottom: 24px;
    position: relative;
    padding-left: 70px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      // transform: translateY(-50%);
      transform: translate(-50%, -50%);
      top: 50%;
      width: var(--diametr);
      height: var(--diametr);
      border-radius: 50%;
      // margin-left: calc(var(--diametr) / 2);
      margin-left: calc(46px / 2);
    }
    &:after {
      --diametr: 46px;
      background: #00ffff;
      opacity: 0.12;
    }
    &:before {
      --diametr: 22px;
      border: 2px solid #00ffff;
    }
    @media only screen and (min-width: $tablet-width) {
      margin-bottom: 36px
    }
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
      @media only screen and (min-width: $tablet-width) {
        font-size: 20px;
      }
    }
  }
}