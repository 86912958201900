@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';

.Landing_InvestFund {
  display: flex;
  align-items: center;
  // min-height: 100vh;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/invest-fund-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
}
.Landing_InvestFundWrapper {
  max-width: 1594px;
  padding: 40px 16px;
  margin: 0 auto;
  display: block;
  @media only screen and (min-width: $desktop-width) {
    display: grid;
    grid-template-areas: 'logo image' 'text image' 'list image';
    grid-template-columns: 1fr 1.5fr;
  }
  @media only screen and (min-width: $tablet-width) {
    padding: 70px 16px;
  }
  @media only screen and (min-width: $wide-width) {
    padding: 70px 16px 70px 66px;
  }
}
.Landing_InvestFundLogo {
  grid-area: logo;
  height: 1.5em;
  margin-bottom: 30px;
  @media only screen and (min-width: $tablet-width) {
    height: 2.5em;
    margin-bottom: 47px;
  }
  img {
    max-height: 100%;
    width: auto;
    max-width: 100%
  }
}
.Landing_InvestFundText {
  grid-area: text;
  font-size: 16px;
  color: $primaryText;
  margin-bottom: 35px;
  @media only screen and (min-width: $tablet-width) {
    margin-bottom: 55px;
    font-size: 18px;
  }
  @media only screen and (min-width: $desktop-width) {
    font-size: 20px;
    line-height: 180%;
    margin-bottom: 92px;
  }
  a {
    color: inherit;
  }
}
.Landing_InvestFundList {
  grid-area: list;
  margin-bottom: 55px;
  @media only screen and (min-width: $desktop-width) {
     margin-bottom: 0;
  }
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
    @media only screen and (min-width: $desktop-width) {
      margin-bottom: 55px;
    }
    img {
      display: block;
      width: 46px;
      height: 46px;
      object-fit: scale-down;
      object-position: center;
      margin-right: 24px;
      @media only screen and (min-width: $tablet-width) {
        margin-right: 36px;
      }
      @media only screen and (min-width: $desktop-width) {
        margin-right: 58px;
      }
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      @media only screen and (min-width: $tablet-width) {
        font-size: 16px;
      }
    }
  }
}
.Landing_InvestFundImage {
  grid-area: image;
  position: relative;
  text-align: center;
  @media only screen and (min-width: $desktop-width) {
    margin-left: 30px;
    text-align: left;
  }
  img {
    max-width: 100%;
    height: auto;
    object-position: 50% 50%;  
    @media only screen and (min-width: $desktop-width) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: scale-down;
      object-position: 100% 50%;      
    }
  }
}