@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_Partners {
  background-image: url('~@/assets/img/landing/partners-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  color: white;

  &Wrapper {
    max-width: 1256px;
    width: 100%;
    padding: 40px 16px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: $tablet-width) {
      padding: 70px 16px;
    }
    @media only screen and (min-width: $wide-width) {
      padding: 140px 16px 140px 36px;
    }
  }

  &Title {
    @include landingTitle;
    color: #00ffff;
    text-align: center;
  }
}

.Landing_PartnersList {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media only screen and (min-width: $tablet-width) {
    margin-top: 50px;
  }
  @media only screen and (min-width: $desktop-width) {
    margin-top: 93px;
  }

  &Corner {
    display: block;
    width: 39px;
    height: 39px;
    position: absolute;
    z-index: 1;
    &.first {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cmask id='path-1-inside-1' fill='white'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M39 0H0V39H9.25376V9.25432H39V0Z'/%3e%3c/mask%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M39 0H0V39H9.25376V9.25432H39V0Z' fill='%2300E0FF' fill-opacity='0.1'/%3e%3cpath d='M0 0V-1H-1V0H0ZM39 0H40V-1H39V0ZM0 39H-1V40H0V39ZM9.25376 39V40H10.2538V39H9.25376ZM9.25376 9.25432V8.25432H8.25376V9.25432H9.25376ZM39 9.25432V10.2543H40V9.25432H39ZM0 1H39V-1H0V1ZM1 39V0H-1V39H1ZM9.25376 38H0V40H9.25376V38ZM10.2538 39V9.25432H8.25376V39H10.2538ZM9.25376 10.2543H39V8.25432H9.25376V10.2543ZM38 0V9.25432H40V0H38Z' fill='%2300FFFF' mask='url(%23path-1-inside-1)'/%3e%3c/svg%3e ");
      top: 0;
      left: 0;
    }
    &.second {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cmask id='path-1-inside-1' fill='white'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H39V39H29.7462V9.25432H0V0Z'/%3e%3c/mask%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0H39V39H29.7462V9.25432H0V0Z' fill='%2300E0FF' fill-opacity='0.1'/%3e%3cpath d='M39 0V-1H40V0H39ZM0 0H-1V-1H0V0ZM39 39H40V40H39V39ZM29.7462 39V40H28.7462V39H29.7462ZM29.7462 9.25432V8.25432H30.7462V9.25432H29.7462ZM0 9.25432V10.2543H-1V9.25432H0ZM39 1H0V-1H39V1ZM38 39V0H40V39H38ZM29.7462 38H39V40H29.7462V38ZM28.7462 39V9.25432H30.7462V39H28.7462ZM29.7462 10.2543H0V8.25432H29.7462V10.2543ZM1 0V9.25432H-1V0H1Z' fill='%2300FFFF' mask='url(%23path-1-inside-1)'/%3e%3c/svg%3e ");
      top: 0;
      right: 0;
    }
    &.third {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cmask id='path-1-inside-1' fill='white'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M39 39H0V0H9.25376V29.7457H39V39Z'/%3e%3c/mask%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M39 39H0V0H9.25376V29.7457H39V39Z' fill='%2300E0FF' fill-opacity='0.1'/%3e%3cpath d='M0 39V40H-1V39H0ZM39 39H40V40H39V39ZM0 0H-1V-1H0V0ZM9.25376 0V-1H10.2538V0H9.25376ZM9.25376 29.7457V30.7457H8.25376V29.7457H9.25376ZM39 29.7457V28.7457H40V29.7457H39ZM0 38H39V40H0V38ZM1 0V39H-1V0H1ZM9.25376 1H0V-1H9.25376V1ZM10.2538 0V29.7457H8.25376V0H10.2538ZM9.25376 28.7457H39V30.7457H9.25376V28.7457ZM38 39V29.7457H40V39H38Z' fill='%2300FFFF' mask='url(%23path-1-inside-1)'/%3e%3c/svg%3e ");
      bottom: 0;
    }
  }

  &Item {
    background: rgba(0, 16, 35, 0.5);
    border-radius: 20px;
    width: calc(50% - 2px);
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    position: relative;
    @media only screen and (min-width: $tablet-width) {
      width: calc(33.33% - 2px);
      height: 273px;
      border-radius: 44px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      &:before {
        content: '';
        display: none;
        position: absolute;
        width: 60px;
        height: 1px;
        border-bottom: 1px dashed #00ffff;
        left: 100%;
        top: 100%;
        z-index: 1;
        transform: translateX(-50%);
        @media only screen and (min-width: $desktop-width) {
          display: block;
        }
      }
      &:after {
        content: '';
        display: none;
        position: absolute;
        height: 60px;
        width: 1px;
        border-right: 1px dashed #00ffff;
        left: 100%;
        top: 100%;
        z-index: 1;
        transform: translateY(-50%);
        @media only screen and (min-width: $desktop-width) {
          display: block;
        }
      }
    }
    img {
      max-height: 60px;
      width: auto;
      max-width: 60%;
      object-fit: scale-down;
      object-position: 50% 50%;
      @media only screen and (min-width: $tablet-width) {
        max-height: none;
      }
    }
  }
}