@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_Crypto_Broker {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/crypto-broker-bg.jpg');
  background-attachment: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  @media only screen and (min-width: $desktop-width) {
    // баг с этим свойством в сафари
    // background-attachment: fixed;
  }
}
.Landing_Crypto_BrokerWrapper {
  max-width: 1365px;
  padding: 40px 16px;
  margin: 0 auto;
  @media only screen and (min-width: $tablet-width) {
//     padding: 135px 16px 70px;
    padding: 105px 16px 70px;
  }
  @media only screen and (min-width: $wide-width) {
//     padding: 200px 16px 70px;
    padding: 140px 16px 70px;
  }
}
.Landing_Crypto_BrokerTitle {
  margin-bottom: 27px;
  @include landingTitle;
}
.Landing_Crypto_BrokerText {
  grid-area: text;
  font-size: 16px;
  line-height: 180%;
  color: $primaryText;
  margin-bottom: 20px;
  max-width: 660px;
  @media only screen and (min-width: $tablet-width) {
    font-size: 20px;
    margin-bottom: 37px;
  }
}
.Landing_Crypto_BrokerList {
  padding: 32px 16px;
  @include blurBGArea;
  border-radius: 30px;
  margin: auto -16px;
  @media only screen and (min-width: $mobile-width) {
    padding: 32px;
    margin: auto;
  }
  @media only screen and (min-width: $tablet-width) {
    padding: 50px 40px;
  }
  @media only screen and (min-width: $wide-width) {
    padding: 62px 36px 62px 81px;
  }
  ul {
    --gap-row: 20px;
    --gap-col: 16px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap-row)) calc(-1 * var(--gap-col));
    @media only screen and (min-width: $tablet-width) {
      --gap-row: 32px;
      --gap-col: 32px;
    }
  }
  li {
    flex-basis: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gap-row) var(--gap-col);
    text-align: center;
    @media only screen and (min-width: $mobile-width) {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
    img {
      display: block;
      height: 40px;
      width: 80px;
      object-fit: scale-down;
      object-position: 50% 50%;
//       object-position: 50% 68%;
      margin-bottom: 20px;
      @media only screen and (min-width: $tablet-width) {
        width: auto;
        height: 58px;
        margin-bottom: 34px;
      }
    }
    span {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      br {
        display: none;
      }
      @media only screen and (min-width: $tablet-width) {
        font-size: 16px;
      }
      @media only screen and (min-width: $desktop-width) {
        br {
          display: block;
        }        
      }
    }
  }
}
.Landing_Crypto_BrokerImage {
  grid-area: image;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: scale-down;
    object-position: 100% 50%;
    margin-left: 30px;
  }
}