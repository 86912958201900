@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_CryptoProcessing {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/crypto-processing-bg.jpg');
  background-attachment: initial;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
  @media only screen and (min-width: $desktop-width) {
    // баг с этим свойством в сафари
    // background-attachment: fixed;
  }
}
.Landing_CryptoProcessingWrapper {
  max-width: 1308px;
  width: 100%;
  padding: 40px 16px;
  margin: 0 auto;
  @media only screen and (min-width: $tablet-width) {
    padding: 70px 16px;
  }
  @media only screen and (min-width: $wide-width) {
    padding: 140px 16px;
  }
}
.Landing_CryptoProcessingTitle {
  @include landingTitle;
  margin-bottom: 27px;
}
.Landing_CryptoProcessingText {
  font-size: 16px;
  line-height: 180%;
  color: $primaryText;
  margin-bottom: 20px;
  max-width: 660px;
  @media only screen and (min-width: $tablet-width) {
    margin-bottom: 76px;
    font-size: 20px;
  }
}
.Landing_CryptoProcessingList {
  max-width: 550px;
  ul {
    padding: 0 0 0 30px;
    list-style: none;
    @media only screen and (min-width: $tablet-width) {
      padding: 0 0 0 68px;
    }
  }  
  li {
    margin-bottom: 20px;
    @media only screen and (min-width: $tablet-width) {
      margin-bottom: 36px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
      @media only screen and (min-width: $tablet-width) {
        font-size: 20px;
      }
    }
  }
}