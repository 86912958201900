@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_mixins.scss';
.Landing_PaymentHub {
  display: flex;
  align-items: center;
  @include screenHeight;
  background-image: url('~@/assets/img/landing/payment-hub-bg.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a172b;
  text-align: left;
}
.Landing_PaymentHubWrapper {
  max-width: 1362px;
  width: 100%;
  padding: 40px 16px;
  margin: 0 auto;
  @media only screen and (min-width: $tablet-width) { 
    padding: 105px 16px 70px;
  }
  @media only screen and (min-width: $wide-width) { 
    padding: 140px 16px 70px;
  }
}
.Landing_PaymentHubTitle {
  @include landingTitle;
  margin-bottom: 30px;
  text-align: center;
  color: #00ffff;
  @media only screen and (min-width: $tablet-width) { 
    margin-bottom: 52px;
  }
}
.Landing_PaymentHubText {
  margin: auto auto 30px;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: $primaryText;
  max-width: 660px;
  @media only screen and (min-width: $tablet-width) { 
    margin: auto auto 105px;
    font-size: 20px;
  }
}
.Landing_PaymentHubList {
  padding: 32px 16px;
  margin: auto -16px;
  @include blurBGArea;
  border-radius: 30px;
  @media only screen and (min-width: $tablet-width) { 
    padding: 32px;
    margin: auto;
  }
  @media only screen and (min-width: $wide-width) { 
    padding: 8px 51px 35px 40px;
  }
  ul {
    --gap-col: 4px;
    --gap-row: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: calc(-1 * var(--gap-row)) calc(-1 * var(--gap-col));
    list-style: none;
    @media only screen and (min-width: $tablet-width) { 
      --gap-col: 8px;
      --gap-row: 36px;
    }
    @media only screen and (min-width: $wide-width) { 
      --gap-col: 0px;
      --gap-row: 50px;
    }
  }
  li {
    width: 33.33%;
    padding: 10px 4px;
    padding: var(--gap-row) var(--gap-col);
    @media only screen and (min-width: $mobile-width) { 
      width: 25%;
    }
    @media only screen and (min-width: $tablet-width) { 
      width: 20%;
    }
    @media only screen and (min-width: $desktop-width) { 
      width: 16.66%;
    }
    @media only screen and (min-width: $wide-width) { 
      width: 14.28%;
    }
    img {
      display: block;
      width: 100%;
      height: 34px;
      object-fit: scale-down;
      object-position: 50% 50%;
      margin: auto;
      @media only screen and (min-width: $tablet-width) { 
        height: 40px;
        object-position: 100% 50%;
        &.align-left {
          object-position: 0 50%;
        }
      }
      @media only screen and (min-width: $desktop-width) { 
        height: 46px;
      }
    }
  }
}
.Landing_PaymentHubListHead {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  margin: auto auto 20px;
  @media only screen and (min-width: $tablet-width) {
    margin-bottom: 30px;
    max-width: none;
  }
  &:before {
    content: '';
    flex-grow: 1;
  }
  img {
    display: inline-block;
    max-height: none;
    width: calc(50% - 8px);
    height: auto;
    margin-right: 16px;
    flex-basis: 50%;    
    &:last-child {
      margin-right: 0;
    }
    @media only screen and (min-width: $tablet-width) {
      margin-right: 144px; 
      flex-basis: auto;
      max-height: 87px;
      width: auto;
    }
  }
}